// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-afpa-add-company-index-jsx": () => import("./../src/pagesAfpa/add-company/index.jsx" /* webpackChunkName: "component---src-pages-afpa-add-company-index-jsx" */),
  "component---src-pages-afpa-app-jsx": () => import("./../src/pagesAfpa/app.jsx" /* webpackChunkName: "component---src-pages-afpa-app-jsx" */),
  "component---src-pages-afpa-create-story-index-jsx": () => import("./../src/pagesAfpa/create-story/index.jsx" /* webpackChunkName: "component---src-pages-afpa-create-story-index-jsx" */),
  "component---src-pages-afpa-detailed-file-view-index-jsx": () => import("./../src/pagesAfpa/detailed-file-view/index.jsx" /* webpackChunkName: "component---src-pages-afpa-detailed-file-view-index-jsx" */),
  "component---src-pages-afpa-duplicate-media-index-jsx": () => import("./../src/pagesAfpa/duplicate-media/index.jsx" /* webpackChunkName: "component---src-pages-afpa-duplicate-media-index-jsx" */),
  "component---src-pages-afpa-edit-branch-index-jsx": () => import("./../src/pagesAfpa/edit-branch/index.jsx" /* webpackChunkName: "component---src-pages-afpa-edit-branch-index-jsx" */),
  "component---src-pages-afpa-edit-organization-index-jsx": () => import("./../src/pagesAfpa/edit-organization/index.jsx" /* webpackChunkName: "component---src-pages-afpa-edit-organization-index-jsx" */),
  "component---src-pages-afpa-featured-photos-index-jsx": () => import("./../src/pagesAfpa/featured-photos/index.jsx" /* webpackChunkName: "component---src-pages-afpa-featured-photos-index-jsx" */),
  "component---src-pages-afpa-featured-stories-index-jsx": () => import("./../src/pagesAfpa/featured-stories/index.jsx" /* webpackChunkName: "component---src-pages-afpa-featured-stories-index-jsx" */),
  "component---src-pages-afpa-index-jsx": () => import("./../src/pagesAfpa/index.jsx" /* webpackChunkName: "component---src-pages-afpa-index-jsx" */),
  "component---src-pages-afpa-keywords-index-jsx": () => import("./../src/pagesAfpa/keywords/index.jsx" /* webpackChunkName: "component---src-pages-afpa-keywords-index-jsx" */),
  "component---src-pages-afpa-manage-companies-index-jsx": () => import("./../src/pagesAfpa/manage-companies/index.jsx" /* webpackChunkName: "component---src-pages-afpa-manage-companies-index-jsx" */),
  "component---src-pages-afpa-moderate-media-index-jsx": () => import("./../src/pagesAfpa/moderate-media/index.jsx" /* webpackChunkName: "component---src-pages-afpa-moderate-media-index-jsx" */),
  "component---src-pages-afpa-privacy-index-jsx": () => import("./../src/pagesAfpa/privacy/index.jsx" /* webpackChunkName: "component---src-pages-afpa-privacy-index-jsx" */),
  "component---src-pages-afpa-search-jsx": () => import("./../src/pagesAfpa/search.jsx" /* webpackChunkName: "component---src-pages-afpa-search-jsx" */),
  "component---src-pages-afpa-sign-up-user-index-jsx": () => import("./../src/pagesAfpa/sign-up-user/index.jsx" /* webpackChunkName: "component---src-pages-afpa-sign-up-user-index-jsx" */),
  "component---src-pages-afpa-terms-index-jsx": () => import("./../src/pagesAfpa/terms/index.jsx" /* webpackChunkName: "component---src-pages-afpa-terms-index-jsx" */),
  "component---src-pages-afpa-upload-index-jsx": () => import("./../src/pagesAfpa/upload/index.jsx" /* webpackChunkName: "component---src-pages-afpa-upload-index-jsx" */),
  "component---src-pages-afpa-view-edit-group-index-jsx": () => import("./../src/pagesAfpa/view-edit-group/index.jsx" /* webpackChunkName: "component---src-pages-afpa-view-edit-group-index-jsx" */),
  "component---src-pages-afpa-view-my-group-index-jsx": () => import("./../src/pagesAfpa/view-my-group/index.jsx" /* webpackChunkName: "component---src-pages-afpa-view-my-group-index-jsx" */),
  "component---src-pages-afpa-view-my-media-index-jsx": () => import("./../src/pagesAfpa/view-my-media/index.jsx" /* webpackChunkName: "component---src-pages-afpa-view-my-media-index-jsx" */)
}

